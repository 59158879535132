import React, {useCallback, useMemo, useRef} from "react";
import {useMediaQuery, useStore} from "modules/hooks";
import {QuestionContainer, QuestionWrapper} from "./common";
import styled from "styled-components";
import {IMAGES_URL, LOCALE} from "modules/constant";
import backgroundFinalScreen from "assets/img/backgroundFinalScreen.jpg";
import {IconArrow} from "components/Icons";
import {Exist} from "components/Exist";
import {Footer, ShareTwitter, ShareFacebook} from "components";
import {shareFB, shareTwitter} from "modules/utils/Share";
import {observer} from "mobx-react";
import ReactAwesomePlayer from "react-awesome-player";
import {createConnextraScriptTag, track} from "modules/utils";

const Container = styled(QuestionContainer)`
	padding: calc(8vh + 100px) 12% 50px;

	@media (max-width: 840px) {
		padding: calc(8vh + 50px) 35px 50px;
	}
`;

interface IWrapperProps {
	background?: null | string;
}

const getWrapperBackground = ({background}: IWrapperProps) =>
	background ? `${IMAGES_URL}image/${background}` : backgroundFinalScreen;

const getBlockBackground = ({background}: IWrapperProps) =>
	background
		? `background: url(${background}) no-repeat center center / cover;`
		: "";

const Wrapper = styled(QuestionWrapper)<IWrapperProps>`
	background: url(${getWrapperBackground}) no-repeat center center / cover;
`;

const FinalTitle = styled.h3`
	font-size: 44px;
	max-width: 500px;
	line-height: 52px;
	letter-spacing: 3px;

	@media (max-width: 1000px) {
		font-size: 18px;
		line-height: 24px;
	}
`;

const FinalDescription = styled.div`
	margin-top: 40px;
	letter-spacing: 2px;
	display: inline-flex;
	align-items: center;
	flex-wrap: wrap;
	line-height: 30px;
`;

const MediaWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	padding: 30px 0;
	max-width: 1600px;
`;

const IconArrowStyled = styled(IconArrow)`
	transform: rotate(-88deg);
	font-size: 11px;
	margin-left: 4px;
	&:before,
	&:after {
		background: #cccccc;
	}
`;

const MediaItemLink = styled.div`
	display: inline;
`;

const SignUpLink = styled.a``;

const MediaItem = styled.div`
	position: relative;
	display: flex;
	background: rgba(0, 0, 0, 0.5);
	border: 2px solid #6b747e;
	border-radius: 6px;
	box-sizing: border-box;

	p {
		position: absolute;
		bottom: 30px;
		left: 40px;
		letter-spacing: 2px;
	}

	${MediaItemLink}, ${SignUpLink} {
		position: absolute;
		text-transform: uppercase;
		bottom: 30px;
		right: 30px;
		letter-spacing: 2px;
		color: #cccccc;

		&:hover {
			color: #ffffff;
			transition: color, 0.25s ease-in-out;
			${IconArrowStyled} {
				&:before,
				&:after {
					background: #ffffff;
					transition: background, 0.25s ease-in-out;
				}
			}
		}
	}
`;

const MediaVideo = styled(MediaItem)`
	max-width: 800px;
	width: 100%;
	margin-bottom: 35px;
	margin-right: 30px;
	flex: 2;

	p {
		font-size: 28px;
	}

	@media (max-width: 1360px) {
		margin-right: 0;
		max-width: 100%;
		min-width: 100%;
	}

	@media (max-width: 840px) {
		p {
			font-size: 12px;
			left: 10px;
			bottom: 15px;
			padding-right: 30px;
		}

		${MediaItemLink}, ${SignUpLink} {
			bottom: 15px;
			font-size: 12px;
			right: 10px;
		}
	}
`;

const WrapperMediaHistory = styled.div`
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	flex: 1;
	align-items: start;
`;

const MediaHistory = styled(MediaItem)`
	max-width: 400px;
	width: 100%;
	margin-bottom: 35px;

	@media (max-width: 1100px) {
		max-width: 48%;
	}

	p {
		left: 20px;
		padding-right: 40px;
	}

	${MediaItemLink}, ${SignUpLink} {
		right: 20px;
	}

	@media (max-width: 840px) {
		p {
			font-size: 12px;
			left: 10px;
			bottom: 15px;
			padding-right: 30px;
		}

		${MediaItemLink}, ${SignUpLink} {
			bottom: 15px;
			font-size: 12px;
			right: 10px;
		}
	}
`;

const SocialButtons = styled.span`
	display: inline-block;
	white-space: nowrap;
	margin: 10px 0;

	button {
		margin-right: 10px;

		&:first-child {
			margin-left: 10px;
		}
	}
`;

const VideoInner = styled.div<IWrapperProps>`
	${getBlockBackground};
	width: 100%;
	padding-top: 60%;
	height: 0;
	position: relative;

	.react-awesome-player-container {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		cursor: pointer;

		.video-js {
			opacity: 0;
			padding: 0;
			height: 100%;
			transition: opacity 0.5s;

			video {
				object-fit: contain;
			}

			.vjs-big-play-button {
				display: block;
			}

			&.vjs-scrubbing,
			&.vjs-waiting,
			&.vjs-seeking,
			&.vjs-playing {
				opacity: 1;

				.vjs-big-play-button {
					display: none;
				}
			}
		}
	}
`;

const MediaHistoryInner = styled.a<IWrapperProps>`
	${getBlockBackground};
	width: 100%;
	padding-top: 55%;
	height: 0;
	position: relative;

	@media (max-width: 640px) {
		padding-top: 112.399999999%;
	}
`;

const VideoCopy = styled.div`
	transition: 0.5s opacity 0.5s;
	opacity: 1;
	position: relative;

	&.hidden {
		opacity: 0;
		position: relative;
		top: 100px;
	}
`;

const ResetLink = styled.a`
	text-decoration: underline;
	cursor: pointer;

	&:hover {
		text-decoration: none;
	}
`;

const RunnerUpTitle = styled.h4`
	font-size: 34px;
	line-height: 40px;
	letter-spacing: 1.36px;

	@media (max-width: 1000px) {
		font-size: 18px;
		line-height: 24px;
		letter-spacing: 3px;
	}
`;

const RunnerUpSubtitle = styled.h5`
	font-size: 20px;
	letter-spacing: 2.5px;
	line-height: 16px;
	margin-bottom: 26px;

	@media (max-width: 1000px) {
		font-size: 14px;
		letter-spacing: 1.17px;
		line-height: 14px;
	}
`;

const MediaRunnerUpWrapper = styled.div`
	flex: 1;
	max-width: 600px;

	&:first-child {
		margin-right: 30px;
	}

	@media (max-width: 1360px) {
		max-width: 100%;
		min-width: 100%;

		&:first-child {
			margin-right: 0;
			margin-bottom: 20px;
		}
	}
`;

const MediaRunnerUpInner = styled.div<IWrapperProps>`
	${getBlockBackground};
	width: 100%;
	padding-top: 60%;
	height: 0;
`;

interface IProps {
	showFooter?: boolean;
}

export const FinalScreen: React.FC<IProps> = observer(
	({showFooter = false}) => {
		const {
			UISquadList,
			I18n,
			UISquadInfo,
			UIQuestionsProgress,
			fingerprint,
		} = useStore();
		const helpBlockRef = useRef<HTMLDivElement>(null);
		const topSquad = UISquadList.topSquad;
		const secondSquad = UISquadList.secondSquad;
		const thirdSquad = UISquadList.thirdSquad;
		const squadInfo = UISquadInfo.getSquadInfo(topSquad?.id);
		const secondSquadInfo = UISquadInfo.getSquadInfo(secondSquad?.id);
		const thirdSquadInfo = UISquadInfo.getSquadInfo(thirdSquad?.id);
		const shareArgs = {
			squadId: topSquad?.id,
			lang: LOCALE,
			user_id: fingerprint.visitorId,
		};
		const translateArgs = {X: topSquad?.full_name};
		const shareText = I18n.t("share.twitter.copy", translateArgs);
		const isDesktopView = !useMediaQuery("(max-width: 1600px)");
		const videoTitle = I18n.t("screens.final.team_video_title", {
			X: topSquad?.full_name,
		});
		const secondSquadTitle = I18n.t("screens.final.second_team", {
			name: secondSquad?.full_name,
			percentage: secondSquad?.percentage.toString(),
		});
		const thirdSquadTitle = I18n.t("screens.final.third_team", {
			name: thirdSquad?.full_name,
			percentage: thirdSquad?.percentage.toString(),
		});

		const onPause = useCallback(() => {
			if (helpBlockRef.current) {
				helpBlockRef.current.style.display = "block";
			}
		}, [helpBlockRef]);

		const startPlaying = useCallback(() => {
			if (helpBlockRef.current) {
				helpBlockRef.current.style.display = "none";
			}

			track({
				linkType: "video",
				linkName: videoTitle,
			});

			createConnextraScriptTag(
				"https://us.connextra.com/dcs/tagController/tag/11935e1e2233/f2p_teampicker_videoplay"
			);

			window.customGTagTrack({sendToPart: "002"});
		}, [videoTitle, helpBlockRef]);

		const trackShopLink = useCallback(
			({currentTarget}: React.SyntheticEvent<HTMLAnchorElement>) => {
				track({
					linkType: "link",
					linkName: "NFL Shop",
					linkURL: currentTarget.href,
				});

				window.customGTagTrack({sendToPart: "003"});
			},
			[]
		);

		const trackGamePassLink = useCallback(
			({currentTarget}: React.SyntheticEvent<HTMLAnchorElement>) =>
				track({
					linkType: "link",
					linkName: "GamePass",
					linkURL: currentTarget.href,
				}),
			[]
		);

		const trackSignUpLink = useCallback(() => {
			window.customGTagTrack({sendToPart: "004"});
		}, []);

		const videoOptions = useMemo(
			() => ({
				poster: squadInfo?.videoImage,
				sources: [
					{
						type: "video/mp4",
						src: `${IMAGES_URL}video/${topSquad.id}.mp4`,
					},
				],
				controlBar: {
					fullscreenToggle: false,
				},
			}),
			[squadInfo, topSquad]
		);

		return (
			<Wrapper>
				<Container>
					<FinalTitle>
						{I18n.t("screens.final.title", translateArgs)}
					</FinalTitle>
					<FinalDescription>
						<span>
							{I18n.t("screens.final.share.first_part")}
							<SocialButtons>
								<ShareFacebook
									onClick={() => shareFB(shareArgs)}
								/>
								<ShareTwitter
									onClick={() =>
										shareTwitter(shareArgs, shareText)
									}
								/>
							</SocialButtons>
							{I18n.t("screens.final.share.last_part")}{" "}
							<ResetLink
								onClick={() => {
									window.customGTagTrack({sendToPart: "00"});
									UIQuestionsProgress.resetProgress();
								}}
							>
								{I18n.t("screens.final.share.try_again")}
							</ResetLink>
						</span>
					</FinalDescription>
					<MediaWrapper>
						<MediaVideo>
							<VideoInner background={squadInfo?.videoImage}>
								<ReactAwesomePlayer
									play={startPlaying}
									pause={onPause}
									options={videoOptions}
								/>
								<VideoCopy ref={helpBlockRef}>
									<p>{videoTitle}</p>
									<SignUpLink
										onClick={trackSignUpLink}
										target="_blank"
										rel="nofollow noreferrer"
										href="https://www.nfl.com/account/sign-up"
									>
										{I18n.t(
											"screens.final.team_video_desc"
										)}{" "}
										<IconArrowStyled />
									</SignUpLink>
								</VideoCopy>
							</VideoInner>
						</MediaVideo>
						<WrapperMediaHistory>
							<MediaHistory>
								<MediaHistoryInner
									onClick={trackShopLink}
									target="_blank"
									rel="nofollow noreferrer"
									href={squadInfo?.shopLink}
									background={squadInfo?.shopImage}
								>
									<p>{I18n.t("screens.final.shop_title")}</p>
									<MediaItemLink>
										<Exist when={isDesktopView}>
											{I18n.t("screens.final.shop_desc")}{" "}
										</Exist>
										<IconArrowStyled />
									</MediaItemLink>
								</MediaHistoryInner>
							</MediaHistory>
							<MediaHistory>
								<MediaHistoryInner
									onClick={trackGamePassLink}
									target="_blank"
									rel="nofollow noreferrer"
									href={squadInfo?.gamePassLink}
									background={squadInfo?.gamePassImage}
								>
									<p>
										{I18n.t("screens.final.gamepass_title")}
									</p>
									<MediaItemLink>
										<Exist when={isDesktopView}>
											{`${I18n.t(
												"screens.final.gamepass_desc"
											)} `}
										</Exist>
										<IconArrowStyled />
									</MediaItemLink>
								</MediaHistoryInner>
							</MediaHistory>
						</WrapperMediaHistory>
					</MediaWrapper>
					<RunnerUpTitle>
						{I18n.t("screens.final.missed_out")}
					</RunnerUpTitle>
					<MediaWrapper>
						<MediaRunnerUpWrapper>
							<RunnerUpSubtitle>
								{secondSquadTitle}
							</RunnerUpSubtitle>
							<MediaItem>
								<MediaRunnerUpInner
									background={secondSquadInfo?.runnerUpImage}
								/>
							</MediaItem>
						</MediaRunnerUpWrapper>
						<MediaRunnerUpWrapper>
							<RunnerUpSubtitle>
								{thirdSquadTitle}
							</RunnerUpSubtitle>
							<MediaItem>
								<MediaRunnerUpInner
									background={thirdSquadInfo?.runnerUpImage}
								/>
							</MediaItem>
						</MediaRunnerUpWrapper>
					</MediaWrapper>
					<Exist when={showFooter}>
						<Footer />
					</Exist>
				</Container>
			</Wrapper>
		);
	}
);
