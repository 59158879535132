import React, {useState} from "react";
import styled from "styled-components";
import {Logo} from "components/Logo";
import {LanguageSwitch} from "components/LanguageSwitch";
import {useStore} from "modules/hooks";
import {Modal, Button} from "components";
import {PersistStorage} from "modules/utils";
import {observer} from "mobx-react";

const ModalContent = styled.div`
	padding: 30px 70px 60px;
	text-align: center;

	p {
		font-size: 16px;
		line-height: 22px;
		margin-bottom: 46px;
		color: #1a1a1a;
	}

	button {
		margin-bottom: 30px;
	}

	@media (max-width: 840px) {
		padding: 30px 20px 60px;
	}
`;

const HIDE_MODAL = JSON.parse(
	PersistStorage.GET("landing_modal") || "false"
) as boolean;

export const LandingPageModal: React.FC = observer(() => {
	const {I18n} = useStore();
	const [hideModal, setHideModal] = useState(HIDE_MODAL);

	const onClose = () => {
		PersistStorage.SET("landing_modal", true);
		setHideModal(true);
	};

	if (hideModal) {
		return null;
	}

	return (
		<Modal>
			<ModalContent>
				<Logo type="medium" textColor="blue" />
				<p>{I18n.t("global.landing_modal.desc")}</p>
				<Button onClick={onClose}>
					{I18n.t("global.landing_modal.button")}
				</Button>
				<LanguageSwitch color="#143CAB" />
			</ModalContent>
		</Modal>
	);
});
