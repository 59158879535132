export const track = (
	params: Record<string, unknown>,
	action = "click_action"
) => {
	window._satellite?.track(action, {
		siteName: "nfl fantasy intl game",
		linkModule: "Favorite Team Picker Actions",
		...params,
	});
};

export function gtag(arg1: unknown, arg2?: unknown, arg3?: unknown) {
	// The rule is disabled because such format is required by documentation.
	// eslint-disable-next-line prefer-rest-params
	window.dataLayer?.push(arguments);
}

export const createConnextraScriptTag = (src: string) => {
	const elementName = "script";
	const SCRIPT_ID = "connextra-tagging";
	document.getElementById(SCRIPT_ID)?.remove();

	const script = document.createElement(elementName);
	script.id = SCRIPT_ID;
	script.src = src;
	script.async = true;
	script.defer = true;

	const firstJS = document.getElementsByTagName(elementName)[0];
	firstJS.parentNode!.insertBefore(script, firstJS);

	return script;
};
