import React, {useCallback} from "react";
import styled from "styled-components";
import {LOCALE, LANGUAGES} from "modules/constant";
import {SessionStorage} from "modules/utils/Storage";
import iconSelectArrow from "assets/img/iconSelectArrow.svg";

interface IProps {
	color?: string;
}

const Select = styled.select<IProps>`
	box-sizing: border-box;
	background: url(${iconSelectArrow}) #fff no-repeat center right 15px;
	width: 210px;
	height: 40px;
	border-radius: 4px;
	font-size: 14px;
	color: ${({color}) => color || "#013369"};
	border: 1px solid;
	border-color: ${({color}) => color || null};
	appearance: none;
	padding: 0 30px 0 15px;
	box-sizing: border-box;
	cursor: pointer;
`;

export const LanguageSwitch: React.FC<IProps> = (props) => {
	const onChange = useCallback(
		(event: React.SyntheticEvent<HTMLSelectElement>) => {
			SessionStorage.SET("language", event.currentTarget.value);
			window.location.reload();
		},
		[]
	);

	return (
		<Select onChange={onChange} value={LOCALE} {...props}>
			{LANGUAGES.map(({title, value, id}) => (
				<option key={id} value={value}>
					{title}
				</option>
			))}
		</Select>
	);
};
