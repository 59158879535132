import React from "react";
import {QuestionWrapper} from "./common";
import {observer} from "mobx-react";
import {loadScript} from "./jebbit";
import styled from "styled-components";
import {Exist} from "components/Exist";
import {Footer} from "components/Footer";
import {APP_ENVIRONMENT, Language, LOCALE} from "modules/constant";

const Wrapper = styled(QuestionWrapper)`
	position: relative;
`;

const FooterWrapper = styled.div`
	box-sizing: border-box;
	width: 100%;
	padding: 0 12% 50px;

	@media (max-width: 840px) {
		padding: 0 35px 50px;
	}
`;

const getSrc = () => {
	if (APP_ENVIRONMENT === "uat") {
		return "https://nflepsilon.jebbit.com/b2bt86h5?draft=true";
	}

	switch (LOCALE) {
		case Language.EN:
			return "https://nflepsilon.jebbit.com/b2bt86h5?L=Owned+Web&deferred=true";
		case Language.DE:
			return "https://nflepsilon.jebbit.com/ypkgckad?L=Owned+Web&deferred=true";
		case Language.ES:
			return "https://nflepsilon.jebbit.com/up8phgvx?L=Owned+Web&deferred=true";
		case Language.PT:
			return "https://nflepsilon.jebbit.com/zjvtgom9?L=Owned+Web&deferred=true";
		default:
			return "https://nflepsilon.jebbit.com/b2bt86h5?L=Owned+Web&deferred=true";
	}
};

interface IProps {
	showFooter?: boolean;
}

export const JebbitScreen: React.FC<IProps> = observer(
	({showFooter = false}) => {
		const src = getSrc();

		return (
			<Wrapper>
				<iframe
					title="Jebbit Data Capture"
					className="jebbit-iframe"
					src={src}
					seamless={true}
					frameBorder="0"
					allowFullScreen
					style={{width: "100%", minHeight: "100vh"}}
					onLoad={loadScript}
				/>
				<Exist when={showFooter}>
					<FooterWrapper>
						<Footer />
					</FooterWrapper>
				</Exist>
			</Wrapper>
		);
	}
);
