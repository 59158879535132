import {makeObservable, computed, action, observable} from "mobx";
import {orderBy, keys, find, reduce, chain, transform} from "lodash";
import {RootStore} from "modules/stores/RootStore";

export class UISquadList<TModel extends RootStore> {
	@observable isExpanded: boolean = false;

	constructor(
		private readonly rootStore: Pick<
			TModel,
			"squads" | "UIQuestionsProgress"
		>
	) {
		makeObservable(this);
	}

	@computed get renderVersion() {
		return this.items.map(({id}) => id).join("");
	}

	@computed get items() {
		const squadsWithWeight = this.rootStore.squads.entities.map(
			(squad) => ({
				...squad,
				weight: this.weights[squad.id] || 0,
				percentage: this.percentages[squad.id] || 0,
			})
		);

		return orderBy(squadsWithWeight, ["weight"], ["desc"]);
	}

	@computed get topSquad() {
		return this.items[0];
	}

	@computed get secondSquad() {
		return this.items[1];
	}

	@computed get thirdSquad() {
		return this.items[2];
	}

	@action toggle() {
		this.isExpanded = !this.isExpanded;
	}

	@computed get weights(): Record<number, number> {
		const answers = this.rootStore.UIQuestionsProgress.answers;
		const allItemsByID = this.rootStore.UIQuestionsProgress.allItemsByID;
		const answeredQuestionsIDs = keys(
			this.rootStore.UIQuestionsProgress.answers
		);

		const pointsPerAnswer = answeredQuestionsIDs.map((questionID) => {
			const selectedOptionID = answers[questionID];
			const question = allItemsByID[questionID];

			if (!question) {
				return {};
			}

			return find(question.options, {id: selectedOptionID})?.points;
		});

		return reduce(
			pointsPerAnswer,
			(acc: Record<string, number>, points?: Record<string, number>) => {
				if (!points) {
					return acc;
				}

				const squadsIDs = keys(points);

				squadsIDs.forEach((squadID) => {
					const currentPoint = acc[squadID] || 0;
					acc[squadID] = currentPoint + points[squadID];
				});

				return acc;
			},
			{}
		);
	}

	@computed get percentages(): Record<number, number> {
		const totalPoints = chain(this.weights)
			.values()
			.reduce(
				(previousValue, currentValue) => previousValue + currentValue
			)
			.value();

		return transform(
			this.weights,
			(result, value, key) =>
				(result[+key] = Math.round((value / totalPoints) * 100))
		);
	}
}
