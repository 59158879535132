export enum LoadState {
	IDLE,
	Requested,
	Received,
}

export enum Language {
	EN = "en",
	ES = "es",
	DE = "de",
	FR = "fr",
	PT = "pt",
}

export enum QuestionType {
	General = "general",
	Slider = "slider",
	Sponsored = "sponsored",
	Text = "text",
}
