import {makeObservable, observable} from "mobx";
import {Api} from "modules/utils/Api";
import {IApiResponse} from "modules/types";
import {ApiModel} from "modules/stores/Base/ApiModel";
import {LoadState} from "modules/constant";

type TResponse = IApiResponse<{country: string}>;

export class Region extends ApiModel<TResponse> {
	@observable public country: string = "";

	constructor() {
		super(Api.Country.get);
		makeObservable(this);
	}

	protected performResponse(resp: TResponse) {
		this.country = (resp.result?.country || "").toLowerCase();
	}

	protected onApiError() {
		this.country = "us";
		this.apiState = LoadState.Received;
	}
}
