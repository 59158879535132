import React, {useEffect} from "react";
import {
	SquadsList,
	Questions,
	Menu,
	ScrollCTA,
	GTagAnalytic,
	LandingPageModal,
} from "components";
import {useStore} from "modules/hooks";
import {observer} from "mobx-react";
import {createConnextraScriptTag} from "modules/utils";

const Home: React.FC = observer(() => {
	const {region, I18n} = useStore();

	useEffect(() => {
		createConnextraScriptTag(
			"https://us.connextra.com/dcs/tagController/tag/11935e1e2233/f2p_teampicker_homepage"
		);
	});

	useEffect(() => {
		void region.request();
		void I18n.request();
	}, [region, I18n]);

	return (
		<GTagAnalytic>
			<Menu />
			<SquadsList />
			<Questions />
			<ScrollCTA />
			<LandingPageModal />
		</GTagAnalytic>
	);
});

export default Home;
