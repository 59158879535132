import styled from "styled-components";

export const IconArrow = styled.div`
	width: 1.375em;
	height: 0.875em;
	position: relative;
	display: inline-block;

	&:before,
	&:after {
		content: "";
		display: block;
		height: 0.1875em;
		width: 0.875em;
		background: #fff;
		position: absolute;
		top: 0;
	}

	&:before {
		left: 0;
		transform: rotateZ(45deg);
	}

	&:after {
		transform: rotateZ(-45deg);
		right: 0;
	}
`;
