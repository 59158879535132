import FingerprintJS from "@fingerprintjs/fingerprintjs";
import {observable, autorun, runInAction} from "mobx";

export class Fingerprint {
	@observable public visitorId: string = "";

	constructor() {
		autorun(async () => {
			const fp = await FingerprintJS.load();
			const {visitorId} = await fp.get();

			runInAction(() => {
				this.visitorId = visitorId;
			});
		});
	}
}
