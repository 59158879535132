import React from "react";
import {useStore} from "modules/hooks";
import styled from "styled-components";
import {observer} from "mobx-react";
import {IconArrow} from "components/Icons/Arrow";

const Wrapper = styled.div`
	position: fixed;
	bottom: 4vh;
	left: 50%;
	transform: translateX(-50%);
	transition: all 0.5s;
	opacity: 0;
	text-align: center;
	cursor: default;
	z-index: 3;

	&.isVisible {
		opacity: 1;
	}

	@media (max-width: 840px) {
		bottom: 2vh;
	}
`;

const Text = styled.div`
	text-transform: uppercase;
	font-size: 12px;
	font-weight: bold;
	letter-spacing: 3px;

	@media (max-width: 840px) {
		display: none;
	}
`;

const Animate = styled.div`
	margin-bottom: 5px;

	@keyframes bounceAlpha {
		0% {
			opacity: 1;
			transform: translateY(0px) scale(1);
		}
		25% {
			opacity: 0;
			transform: translateY(10px) scale(0.9);
		}
		26% {
			opacity: 0;
			transform: translateY(-15px) scale(0.9);
		}
		55% {
			opacity: 1;
			transform: translateY(0px) scale(1);
		}
	}

	animation-name: bounceAlpha;
	animation-duration: 1.5s;
	animation-delay: 0.3s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
`;

export const ScrollCTA: React.FC = observer(() => {
	const {UIQuestionsProgress, I18n} = useStore();
	const className = UIQuestionsProgress.isScrollCTAVisible ? "isVisible" : "";

	return (
		<Wrapper className={className}>
			<Animate>
				<IconArrow />
			</Animate>
			<Text>{I18n.t("scroll")}</Text>
		</Wrapper>
	);
});
