import React, {useState} from "react";
import {IQuestion} from "modules/stores/Models";
import {QuestionWrapper, QuestionTitle, QuestionContainer} from "./common";
import styled from "styled-components";
import backgroundSponsoredQuestion from "assets/img/backgroundSponsoredQuestion.jpg";
import {useStore} from "modules/hooks";
import {IBGImageProps} from "modules/types";
import {getBGImage} from "modules/utils";
import {IMAGES_URL} from "modules/constant";

interface IWrapperProps {
	background: null | string;
}

const getWrapperBackground = ({background}: IWrapperProps) =>
	background
		? `${IMAGES_URL}image/${background}`
		: backgroundSponsoredQuestion;

const Wrapper = styled(QuestionWrapper)<IWrapperProps>`
	background: url(${getWrapperBackground}) no-repeat center center / cover;
`;

const OptionsList = styled.div`
	display: flex;
`;

const Option = styled.div<IBGImageProps>`
	background: ${getBGImage};
	width: 600px;
	height: 380px;
	position: relative;
	cursor: pointer;
	border: 1px solid #cccbcc;
	overflow: hidden;
	border-radius: 4px;
	margin-right: 40px;
	transition: all 0.5s;

	img {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}

	&:last-child {
		margin-right: 0;
	}

	&.active {
		box-shadow: inset 0 0 0 5px #fff;
	}

	&.fade {
		opacity: 0.5;
	}
`;

const OptionTitle = styled.div`
	width: 100%;
	box-sizing: border-box;
	padding: 0 30px;
	font-size: 30px;
	letter-spacing: 3px;
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
`;

const getStateClass = (currentID: number, selectedID: number | null) => {
	if (selectedID === null) {
		return "";
	}

	if (currentID === selectedID) {
		return "active";
	}

	return "fade";
};

export const QuestionSponsored: React.FC<IQuestion> = ({
	title,
	options,
	id: questionID,
	background,
}) => {
	const {UIQuestionsProgress} = useStore();
	const [selectedID, setSelectedID] = useState<null | number>(null);

	return (
		<Wrapper background={background}>
			<QuestionContainer>
				<QuestionTitle>{title}</QuestionTitle>
				<OptionsList>
					{options.map(({id, title, image}) => (
						<Option
							key={id}
							className={getStateClass(id, selectedID)}
							imageSrc={image}
							onClick={() => {
								UIQuestionsProgress.selectAnswer(
									questionID,
									id
								);
								setSelectedID(id);
							}}
						>
							<OptionTitle>{title}</OptionTitle>
						</Option>
					))}
				</OptionsList>
			</QuestionContainer>
		</Wrapper>
	);
};
