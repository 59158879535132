import React from "react";

import logoTextEN from "assets/img/logoTextEN.svg";
import logoTextDE from "assets/img/logoTextDE.svg";
import logoTextES from "assets/img/logoTextES.svg";
import logoTextFR from "assets/img/logoTextFR.svg";
import logoTextPT from "assets/img/logoTextPT.svg";

import logoTextBlueEN from "assets/img/logoTextBlueEN.svg";
import logoTextBlueDE from "assets/img/logoTextBlueDE.svg";
import logoTextBlueES from "assets/img/logoTextBlueES.svg";
import logoTextBlueFR from "assets/img/logoTextBlueFR.svg";
import logoTextBluePT from "assets/img/logoTextBluePT.svg";

import logoBell from "assets/img/logoBell.png";

import logo from "assets/img/logo.svg";
import styled from "styled-components";
import {LOCALE} from "modules/constant";
import {observer} from "mobx-react";
import {useStore} from "modules/hooks";

const WrapperSmall = styled.div`
	display: flex;
	align-items: center;

	.logo {
		margin-left: 10px;
		height: 56px;

		&:first-child {
			margin-left: 0;
		}
	}

	.sponsor {
		margin-left: 10px;
	}

	@media (max-width: 800px) {
		.logo {
			height: 38px;
		}
	}
`;

const WrapperMedium = styled.div`
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	margin: 0 auto 50px;

	.logo {
		&.title {
			height: 48px;
		}

		&.slogan {
			margin-top: 10px;
			height: 125px;
		}
	}

	.sponsor {
		margin-top: 10px;
	}

	@media (max-width: 1200px) {
		max-width: 260px;
		margin: 0 auto 30px;
	}
`;

const WrapperBig = styled.div`
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	margin: 0 auto 50px;

	.logo {
		margin-top: 10px;
		max-width: 100%;

		&:first-child {
			margin-top: 0;
		}
	}

	.sponsor {
		margin-top: 10px;
	}

	@media (max-width: 1200px) {
		max-width: 260px;
		margin: 0 auto 30px;
	}
`;

const WrapperFixed = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 70px;
	position: fixed;
	left: 12%;
	top: calc(9vh - 10px);
	opacity: 0;
	transition: opacity 0.5s;

	.logo {
		margin-left: 10px;
		height: 56px;

		&:first-child {
			margin-left: 0;
		}
	}

	.sponsor {
		margin-left: 10px;
	}

	@media (max-width: 800px) {
		.logo {
			height: 38px;
		}
	}

	@media (max-width: 480px) {
		left: 70px;
	}

	&.visible {
		opacity: 1;
	}
`;

interface ILogoText {
	white: string;
	blue: string;
}

const logoByLanguage: Record<string, ILogoText> = {
	en: {
		white: logoTextEN,
		blue: logoTextBlueEN,
	},
	de: {
		white: logoTextDE,
		blue: logoTextBlueDE,
	},
	es: {
		white: logoTextES,
		blue: logoTextBlueES,
	},
	fr: {
		white: logoTextFR,
		blue: logoTextBlueFR,
	},
	pt: {
		white: logoTextPT,
		blue: logoTextBluePT,
	},
};

export const SponsorImg = styled.img`
	max-height: 38px;
	width: auto;
`;

const sponsorByCountry: Record<string, {src: string; alt: string}> = {
	ca: {
		src: logoBell,
		alt: "Match Eatery & Public House",
	},
};

const SponsorLogo: React.FC = () => {
	const {region} = useStore();
	const sponsorImg = sponsorByCountry[region.country.toLocaleLowerCase()];

	if (!sponsorImg) {
		return null;
	}

	const {src, alt} = sponsorImg;

	return <SponsorImg className="sponsor" src={src} alt={alt} />;
};

const logoText = logoByLanguage[LOCALE] || logoByLanguage.en;

interface ILogoProps {
	type?: "big" | "medium" | "small" | "fixed";
	textColor?: "white" | "blue";
}

const LogoContent: React.FC<ILogoProps> = ({textColor = "white"}) => (
	<React.Fragment>
		<img className="logo title" src={logo} alt="NFL" />
		<img
			className="logo slogan"
			src={logoText[textColor]}
			alt="Lets find you a team"
		/>
		<SponsorLogo />
	</React.Fragment>
);

export const Logo: React.FC<ILogoProps> = observer(
	({type = "fixed", textColor, ...rest}) => {
		const {UIQuestionsProgress} = useStore();

		if (type === "small") {
			return (
				<WrapperSmall {...rest}>
					<LogoContent textColor={textColor} />
				</WrapperSmall>
			);
		}

		if (type === "medium") {
			return (
				<WrapperMedium {...rest}>
					<LogoContent textColor={textColor} />
				</WrapperMedium>
			);
		}

		if (type === "big") {
			return (
				<WrapperBig {...rest}>
					<LogoContent textColor={textColor} />
				</WrapperBig>
			);
		}

		if (UIQuestionsProgress.isEmpty) {
			return null;
		}

		const logoClass = UIQuestionsProgress.isLogoVisible ? "visible" : "";

		return (
			<WrapperFixed className={logoClass} {...rest}>
				<LogoContent textColor={textColor} />
			</WrapperFixed>
		);
	}
);
