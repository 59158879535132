import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {useStore} from "modules/hooks";
import {AB_EXPERIMENT_NAME, QuestionType} from "modules/constant";
import {QuestionText} from "./QuestionText";
import {QuestionGeneral} from "./QuestionGeneral";
import {QuestionSlider} from "./QuestionSlider";
import {QuestionSponsored} from "./QuestionSponsored";
import {FinalScreen} from "./FinalScreen";
import {JebbitScreen} from "./JebbitScreen";
import {TrackQuestionViewingState} from "./common";
import {Exist} from "components/Exist";
import {track} from "modules/utils";
import {
	Experiment,
	Variant,
	emitter,
	mixpanelHelper,
} from "@marvelapp/react-ab-test";
import {ResultScreen} from "./ResultScreen";

const {General, Slider, Sponsored, Text} = QuestionType;

const questionMap = new Map([
	[Text, QuestionText],
	[Slider, QuestionSlider],
	[Sponsored, QuestionSponsored],
	[General, QuestionGeneral],
]);

emitter.defineVariants(AB_EXPERIMENT_NAME, ["A", "B"]);
mixpanelHelper.enable();

export const Questions: React.FC = observer(() => {
	const {questions, UIQuestionsProgress, UISquadList} = useStore();
	const {
		finalScreenID,
		isAllQuestionsAnswered,
		isJebbitComplete,
		jebbitScreenID,
		resultScreenID,
	} = UIQuestionsProgress;

	useEffect(() => {
		void questions.request();
	}, [questions]);

	useEffect(() => {
		if (isAllQuestionsAnswered) {
			track({
				linkType: "quiz complete",
				linkName: UISquadList.topSquad?.full_name,
				quiz_complete: true,
			});
		}
	}, [isAllQuestionsAnswered, UISquadList]);

	useEffect(() => {
		window.addEventListener(
			"message",
			(event) => {
				if (event.origin !== "https://nflepsilon.jebbit.com") {
					return;
				}

				if (event.data === "jebbit-form-submitted") {
					UIQuestionsProgress.submitJebbit();
				}
			},
			false
		);
	}, [UIQuestionsProgress]);

	const questionsList = UIQuestionsProgress.progressItems.map((question) => {
		const {type, id} = question;
		const Component = questionMap.get(type);

		return Component ? (
			<TrackQuestionViewingState key={id} id={question.id}>
				<Component {...question} />
			</TrackQuestionViewingState>
		) : null;
	});

	return (
		<React.Fragment>
			{questionsList}
			<Exist when={isAllQuestionsAnswered}>
				<Experiment name={AB_EXPERIMENT_NAME}>
					<Variant name="A">
						<TrackQuestionViewingState id={jebbitScreenID}>
							<JebbitScreen />
						</TrackQuestionViewingState>
						<Exist when={isJebbitComplete}>
							<TrackQuestionViewingState id={resultScreenID}>
								<ResultScreen />
							</TrackQuestionViewingState>
							<TrackQuestionViewingState id={finalScreenID}>
								<FinalScreen showFooter={true} />
							</TrackQuestionViewingState>
						</Exist>
					</Variant>
					<Variant name="B">
						<TrackQuestionViewingState id={resultScreenID}>
							<ResultScreen />
						</TrackQuestionViewingState>
						<TrackQuestionViewingState id={finalScreenID}>
							<FinalScreen />
						</TrackQuestionViewingState>
						<TrackQuestionViewingState id={jebbitScreenID}>
							<JebbitScreen showFooter={true} />
						</TrackQuestionViewingState>
					</Variant>
				</Experiment>
			</Exist>
		</React.Fragment>
	);
});
