import React, {Fragment, useEffect} from "react";
import {useStore} from "modules/hooks";
import {gtag} from "modules/utils";
import {observer} from "mobx-react";
import {IGTagTrackArg} from "modules/types";

/**
 * We allow script to be added only for Canada
 */
const ALLOWED_COUNTRIES = ["ca"];
const SCRIPT_TAG_ID = "canada-pixel";
const G_TAG_ID = "DC-10179195";

export const GTagAnalytic: React.FC = observer(({children}) => {
	const {region} = useStore();
	const country = region.country;

	useEffect(() => {
		const hasScript = Boolean(document.getElementById(SCRIPT_TAG_ID));

		if (!hasScript && ALLOWED_COUNTRIES.includes(country)) {
			const script = document.createElement("script");
			script.id = SCRIPT_TAG_ID;
			script.async = true;
			script.onload = () => {
				window.dataLayer = window.dataLayer || ([] as unknown[]);

				window.customGTagTrack = ({
					eventName = "event",
					eventAction = "conversion",
					sendToPart = "",
					sendTo,
				}: IGTagTrackArg) =>
					gtag(eventName, eventAction, {
						allow_custom_scripts: true,
						send_to:
							sendTo ||
							`${G_TAG_ID}/nflte0/nflte${sendToPart}+standard`,
					});

				gtag("js", new Date());
				gtag("config", G_TAG_ID);
				window.customGTagTrack({sendToPart: "0"});
			};

			script.src = `https://www.googletagmanager.com/gtag/js?id=${G_TAG_ID}`;
			document.head.appendChild(script);
		}
	}, [country]);

	return <Fragment>{children}</Fragment>;
});
