import React, {useEffect} from "react";
import styled from "styled-components";
import {useStore} from "modules/hooks";
import {useVisible} from "react-hooks-visible";

export const QuestionWrapper = styled.div`
	width: 100%;
	min-height: 100vh;
`;

export const QuestionTitle = styled.h3`
	margin-bottom: 2.5em;
	font-size: 26px;
	line-height: 32px;
	letter-spacing: 3px;
	padding-right: 320px;

	@media (max-width: 1200px) {
		margin-bottom: 2em;
	}

	@media (max-width: 1000px) {
		padding-right: 0;
	}

	@media (max-width: 840px) {
		font-size: 18px;
		line-height: 24px;
	}
`;

export const QuestionCounter = styled(QuestionTitle)`
	font-weight: 600;
	margin-bottom: 1em;
`;

export const QuestionContainer = styled.div`
	padding: calc(8vh + 130px) 12% calc(4vh + 60px);
	box-sizing: border-box;
	min-height: 100%;
	position: relative;
	z-index: 2;

	@media (max-width: 840px) {
		padding: calc(8vh + 50px) 35px calc(4vh + 60px);
	}
`;

const Wrapper = styled.div``;

interface ITrackQuestionViewingStateProps {
	id: number;
}

export const TrackQuestionViewingState: React.FC<ITrackQuestionViewingStateProps> = (
	props
) => {
	const {children, id} = props;
	const {UIQuestionsProgress} = useStore();
	const [targetRef, visible] = useVisible<HTMLDivElement, boolean>(
		(vi: number) => vi > 0.5
	);

	useEffect(() => {
		if (visible) {
			UIQuestionsProgress.setViewedQuestion(id);
		}
	}, [visible, id, UIQuestionsProgress]);

	return (
		<Wrapper id={`question-${id}`} ref={targetRef}>
			{children}
		</Wrapper>
	);
};
