import React, {RefObject, useEffect, useState} from "react";
import {StoreContext} from "modules/stores";
import {getElementSize} from "modules/utils";

export const useStore = () => {
	const store = React.useContext(StoreContext);

	if (!store) {
		throw new Error("useStore must be used within a StoreProvider.");
	}

	return store;
};

export const useMediaQuery = (query: string) => {
	const mediaMatch = window.matchMedia(query);
	const [matches, setMatches] = useState(mediaMatch.matches);

	useEffect(() => {
		const handler = (e: MediaQueryListEvent) => setMatches(e.matches);

		if ("addListener" in mediaMatch) {
			mediaMatch.addListener(handler);
		} else {
			(mediaMatch as MediaQueryList).addEventListener("change", handler);
		}

		return () => {
			if ("addListener" in mediaMatch) {
				mediaMatch.removeListener(handler);
			} else {
				(mediaMatch as MediaQueryList).removeEventListener(
					"change",
					handler
				);
			}
		};
	});

	return matches;
};

export interface ISliderData {
	is_show_nav: boolean;
	is_disabled_left: boolean;
	is_disabled_right: boolean;
}

export const useHasScroll = (ref: RefObject<HTMLDivElement>) => {
	const [hasScroll, setHasScroll] = useState(false);

	useEffect(() => {
		const element = ref.current;

		if (!element) {
			return;
		}

		const children = element.children;
		const {width} = getElementSize(element);

		for (const nodeName in children) {
			const child = children[nodeName];

			if (child instanceof HTMLElement) {
				const childSize = getElementSize(child);
				const allowedPrecision = 30;

				if (
					Math.round(childSize.width - allowedPrecision) >
					Math.round(width)
				) {
					setHasScroll(true);
					return;
				}
			}
		}

		setHasScroll(false);
	}, [ref]);

	return hasScroll;
};

export const useSlider = (
	ref: RefObject<HTMLDivElement>,
	is_mobile = false
) => {
	const [element, setElement] = useState<HTMLDivElement | null>(ref.current);
	const [state, setState] = useState<ISliderData>({
		is_show_nav: false,
		is_disabled_left: false,
		is_disabled_right: false,
	});
	const scroll_size = is_mobile ? 260 : 270;

	useEffect(() => {
		if (ref.current) {
			setState({
				is_show_nav: ref.current.scrollWidth > ref.current.clientWidth,
				is_disabled_left: ref.current.scrollLeft === 0,
				is_disabled_right:
					ref.current.scrollLeft + ref.current.clientWidth >=
					ref.current.scrollWidth,
			});
		}

		setElement(ref.current);
	}, [ref]);

	if (!element) {
		return;
	}

	const scrollSlider = (right = false) => {
		const scrolledLeft = right
			? element.scrollLeft + scroll_size
			: element.scrollLeft - scroll_size;

		element.scroll({
			left: scrolledLeft,
			behavior: "smooth",
		});

		setState({
			...state,
			is_disabled_left: scrolledLeft <= 0,
			is_disabled_right:
				scrolledLeft + element.clientWidth >= element.scrollWidth,
		});
	};

	return {
		...state,
		scrollRight: () => scrollSlider(true),
		scrollLeft: () => scrollSlider(),
	};
};
