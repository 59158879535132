import {IBGImageProps} from "modules/types";
import {IMAGES_URL} from "modules/constant";

export const getBGImage = ({imageSrc}: IBGImageProps) => {
	return `url(${IMAGES_URL}image/${imageSrc}) rgba(0, 0, 0, .5) no-repeat center center / cover;`;
};

export const getElementSize = (element: HTMLElement) => {
	const {
		paddingTop,
		paddingBottom,
		paddingLeft,
		paddingRight,
	} = getComputedStyle(element);

	const elementHeight = element.offsetHeight; // height with padding
	const elementWidth = element.offsetWidth; // width with padding

	return {
		height:
			elementHeight - parseFloat(paddingTop) - parseFloat(paddingBottom),
		width:
			elementWidth - parseFloat(paddingLeft) - parseFloat(paddingRight),
	};
};

export * from "./Api";
export * from "./PersistStorage";
export * from "./Storage";
export * from "./slideScreen";
export * from "./analytic";
