import {Api} from "modules/utils/Api";
import {
	Squad,
	Question,
	Region,
	I18n,
	Fingerprint,
} from "modules/stores/Models";
import {JSONCollection} from "modules/stores/Collections";
import {
	UISquadList,
	UIQuestionsProgress,
	UISquadInfo,
} from "modules/stores/UIStores";

export class RootStore {
	fingerprint: Fingerprint;
	squads: JSONCollection<Squad>;
	questions: JSONCollection<Question>;
	UISquadList: UISquadList<RootStore>;
	UIQuestionsProgress: UIQuestionsProgress<RootStore>;
	UISquadInfo: UISquadInfo<RootStore>;
	region: Region;
	I18n: I18n;

	constructor() {
		this.fingerprint = new Fingerprint();
		this.squads = new JSONCollection(Squad, Api.JSON.squads);
		this.questions = new JSONCollection(Question, Api.JSON.questions);
		this.UISquadList = new UISquadList(this);
		this.UIQuestionsProgress = new UIQuestionsProgress(this);
		this.region = new Region();
		this.I18n = new I18n();
		this.UISquadInfo = new UISquadInfo(this);
	}
}

export default RootStore;
