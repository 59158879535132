import React from "react";
import {inRange, toInteger, throttle} from "lodash";

const getTranslateXY = (element: HTMLElement) => {
	const matrix = new DOMMatrixReadOnly(getComputedStyle(element).transform);

	return {
		translateX: matrix.m41,
		translateY: matrix.m42,
	};
};

const getClientX = (event: TouchEvent | MouseEvent) => {
	if (window.TouchEvent && event instanceof TouchEvent) {
		return event.touches[0].clientX;
	}

	return (event as MouseEvent).clientX;
};

export const startSlide = (
	onUpdateIndex: (index: number) => void,
	onSlideComplete: (questionID: number, optionID: number) => void
) => (
	event:
		| React.MouseEvent<HTMLButtonElement>
		| React.TouchEvent<HTMLButtonElement>
) => {
	const element = event.currentTarget;
	const parent = element.parentNode;

	if (!(parent instanceof HTMLDivElement)) {
		return;
	}

	const buttons = element.nextElementSibling?.querySelectorAll("button");

	if (!buttons?.length) {
		return;
	}

	element.classList.add("in-drag");

	const parentXOffset = parent.getBoundingClientRect().left;

	const optionsData = Array.from(buttons, (element, index) => {
		const {questionId, optionId} = element.dataset;
		const offsetX = element.getBoundingClientRect().left - parentXOffset;
		const width = element.offsetWidth;

		return {
			index,
			width,
			leftEdge: offsetX,
			rightEdge: offsetX + width,
			questionID: toInteger(questionId),
			optionID: toInteger(optionId),
		};
	});

	const firstItem = optionsData[0];
	const lastItem = optionsData[optionsData.length - 1];
	const minVal = firstItem.leftEdge;
	const maxVal = lastItem.rightEdge;

	let collisionIndex: number;

	const move = (event: MouseEvent | TouchEvent) => {
		const newXOffset = Math.min(
			Math.max(getClientX(event) - parentXOffset, minVal),
			maxVal
		);

		element.style.left = `${newXOffset}px`;

		const collision = optionsData.find(({leftEdge, rightEdge}) =>
			inRange(newXOffset, leftEdge, rightEdge)
		);

		if (collision && collision.index !== collisionIndex) {
			collisionIndex = collision.index;
			onUpdateIndex(collisionIndex);
		}
	};

	const stopMove = () => {
		element.classList.remove("in-drag");
		const option = optionsData[collisionIndex];

		if (option) {
			const {questionID, optionID, leftEdge, width} = option;
			const middle = leftEdge + width / 2;

			element.style.left = `${middle}px`;
			onSlideComplete(questionID, optionID);
		}

		document.removeEventListener("mousemove", move);
		document.removeEventListener("mouseup", stopMove);
	};

	document.addEventListener("mousemove", move);
	document.addEventListener("mouseup", stopMove);

	element.addEventListener("touchmove", move);
	element.addEventListener("touchend", stopMove);
	element.addEventListener("touchcancel", stopMove);
};

export const startDrag = (
	onUpdateIndex: (index: number) => void,
	onSlideComplete: (questionID: number, optionID: number) => void
	// eslint-disable-next-line sonarjs/cognitive-complexity
) => (
	event:
		| React.TouchEvent<HTMLButtonElement>
		| React.MouseEvent<HTMLButtonElement>
) => {
	const element = event.currentTarget;
	const content = element.nextElementSibling;

	if (!(content instanceof HTMLElement)) {
		return;
	}

	const initialX = window.innerWidth / 2;
	const buttons = content.querySelectorAll("button");

	if (!buttons?.length) {
		return;
	}

	element.classList.add("in-drag");

	const optionsData = Array.from(buttons, (element, index) => {
		const {questionId, optionId} = element.dataset;
		const offsetX = element.offsetLeft;
		const width = element.offsetWidth;

		return {
			index,
			width,
			leftEdge: offsetX,
			center: offsetX + width / 2,
			rightEdge: offsetX + width,
			questionID: toInteger(questionId),
			optionID: toInteger(optionId),
		};
	});

	let collisionIndex: number;

	const moveContent = throttle(
		(content: HTMLElement, direction: -1 | 0 | 1) => {
			if (!direction) {
				return;
			}

			const currentX = Math.abs(getTranslateXY(content).translateX);

			if (!currentX && direction < 1) {
				return;
			}

			let newOption = optionsData[0];

			if (currentX) {
				const currentIndex = optionsData.findIndex(
					({leftEdge, rightEdge}) =>
						inRange(currentX, leftEdge, rightEdge)
				);

				const newIndex = Math.max(
					Math.min(currentIndex + direction, optionsData.length - 1),
					0
				);

				newOption = optionsData[newIndex];
			}

			collisionIndex = newOption.index;
			onUpdateIndex(collisionIndex);
			content.style.transform = `translateX(${-newOption.center}px)`;
		},
		500,
		{leading: true, trailing: false}
	);

	const move = (event: TouchEvent | MouseEvent) => {
		const maxShiftX = 100;
		const moveX = getClientX(event);
		const direction = moveX > initialX ? 1 : -1;
		const moveDiffX = Math.abs(moveX - initialX);
		const shouldMoveContent = moveDiffX > maxShiftX / 2;

		const minBtnLeftX = initialX - maxShiftX;
		const maxBtnLeftX = initialX + maxShiftX;
		const newBtnOffsetX = Math.min(
			Math.max(moveX, minBtnLeftX),
			maxBtnLeftX
		);

		element.style.left = `${newBtnOffsetX}px`;

		if (shouldMoveContent) {
			moveContent(content, direction);
		}
	};

	const stopMove = () => {
		moveContent.cancel();
		element.classList.remove("in-drag");
		element.style.left = `50%`;

		if (collisionIndex !== undefined) {
			const {questionID, optionID} = optionsData[collisionIndex];
			onSlideComplete(questionID, optionID);
		}

		element.removeEventListener("touchmove", move);
		element.removeEventListener("touchend", stopMove);
		element.removeEventListener("touchcancel", stopMove);
		document.removeEventListener("mousemove", move);
		document.removeEventListener("mouseup", stopMove);
	};

	element.addEventListener("touchmove", move);
	element.addEventListener("touchend", stopMove);
	element.addEventListener("touchcancel", stopMove);

	document.addEventListener("mousemove", move);
	document.addEventListener("mouseup", stopMove);
};

export const updateDragIconPosition = (
	optionElement: HTMLButtonElement,
	dragButton?: HTMLButtonElement | null
) => {
	if (!dragButton) {
		return;
	}

	const width = optionElement.offsetWidth;
	const elementPosLeft = optionElement.getBoundingClientRect().left;
	const middlePos = elementPosLeft + width / 2;

	/**
	 * Fix antsy animation because of React render
	 */
	setTimeout(() => {
		dragButton.style.left = `${middlePos}px`;
	}, 1);
};
