import React from "react";
import styled from "styled-components";

const ModalWrapper = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.5);
	z-index: 15;
	display: flex;
	justify-content: center;
	align-items: center;
`;

interface IModalProps {
	width?: string;
}

const ModalInner = styled.div<IModalProps>`
	position: relative;
	box-sizing: border-box;
	background: #fff;
	border-radius: 6px;
	max-width: ${(props) => props.width || "600px"};
	width: 95%;
`;

interface IProps {
	children: React.ReactNode;
	onClose?: (args: React.SyntheticEvent<HTMLButtonElement>) => null | void;
	width?: string;
}

export const Modal: React.FC<IProps> = ({children, ...rest}: IProps) => (
	<ModalWrapper>
		<ModalInner {...rest}>{children}</ModalInner>
	</ModalWrapper>
);

export * from "./LandingPageModal";
