import React, {useCallback} from "react";
import styled from "styled-components";
import {eq} from "lodash";
import {useStore} from "modules/hooks";
import {observer} from "mobx-react";

const StepItem = styled.div`
	flex: 1;
	border-right: 20px solid #002247;
	margin-bottom: 1px;
	padding-right: 20px;
	transition: all 0.5s;
	display: flex;
	align-items: center;
	font-size: 14px;
	letter-spacing: 1px;
	line-height: 18px;
	cursor: default;
	padding-left: 5.6vw;

	&:last-child {
		margin-bottom: 0;
	}

	&.active {
		border-color: #1b48e0;
		cursor: pointer;
	}

	&.answered {
		border-color: #1b48e0;
		cursor: pointer;
	}

	&.current {
		background: #002247;
		border-color: #143cab;
	}

	@media (max-width: 840px) {
		padding-right: 10px;
		border-right-width: 10px;
	}
`;

interface IMenuItemClassNameArgs {
	hasAnswer: boolean;
	isViewing: boolean;
	isActive: boolean;
}

const getMenuItemClassName = ({
	hasAnswer,
	isViewing,
	isActive,
}: IMenuItemClassNameArgs) => {
	const classList = [];

	if (isViewing) {
		classList.push("current");
	}

	if (hasAnswer) {
		classList.push("answered");
	}

	if (isActive) {
		classList.push("active");
	}

	return classList.join(" ");
};

interface IMenuItemProps {
	id: number;
	short_title: string;
	hasAnswer: boolean;
	isActive: boolean;
	showTitle?: boolean;
	index: number;
}

export const MenuItem: React.FC<IMenuItemProps> = observer((props) => {
	const {
		id,
		hasAnswer,
		short_title,
		index,
		isActive,
		showTitle = false,
	} = props;
	const {UIQuestionsProgress, I18n} = useStore();
	const isViewing = eq(id, UIQuestionsProgress.viewedQuestionID);
	const className = getMenuItemClassName({hasAnswer, isViewing, isActive});
	const isTitleVisible = hasAnswer || isActive || showTitle;
	const hasAnchor = hasAnswer || isActive;

	const moveTo = useCallback(() => {
		if (hasAnchor) {
			document.getElementById(`question-${id}`)?.scrollIntoView({
				block: "end",
				behavior: "smooth",
			});
		}
	}, [id, hasAnchor]);

	return (
		<StepItem key={id} className={className} onClick={moveTo}>
			{isTitleVisible
				? short_title
				: `${I18n.t("menu.item.question")} ${index + 1}`}
		</StepItem>
	);
});
