import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {IQuestion} from "modules/stores/Models";
import {QuestionWrapper, QuestionTitle, QuestionContainer} from "./common";
import styled, {css} from "styled-components";
import {Logo} from "components/Logo";
import {useHasScroll, useStore} from "modules/hooks";
import {PureButton} from "components/Button";
import {IBGImageProps} from "modules/types";
import iconDrag from "assets/img/iconDrag.svg";
import {size, toInteger} from "lodash";
import {
	startSlide,
	startDrag,
	updateDragIconPosition,
	getBGImage,
} from "modules/utils";

const Wrapper = styled(QuestionWrapper)`
	position: relative;
	text-align: center;
`;

const Container = styled(QuestionContainer)`
	padding-left: 0;
	padding-right: 0;
`;

const QuestionContentContainer = styled.div`
	width: 100%;
	overflow: hidden;
	box-sizing: border-box;
	padding: 0 5%;

	@media (max-width: 840px) {
		padding: 0 35px;
	}
`;

const OptionsList = styled.div`
	position: relative;
	display: flex;
	justify-content: space-around;
	width: 100%;
	z-index: 1;
`;

const ImagesList = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	will-change: contents;
`;

const BGImage = styled.div<IBGImageProps>`
	background: ${getBGImage};
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	transition: all 1.5s;
	z-index: 1;
	opacity: 0;

	&.active {
		opacity: 1;
	}
`;

const Option = styled(PureButton)`
	cursor: pointer;
	color: #fff;
	font-size: 16px;
	letter-spacing: 2px;
	padding: 0 30px 15px;
	text-align: center;
	transition: background 0.5s;
	background: linear-gradient(
			to right,
			rgba(255, 255, 255, 0.5),
			rgba(255, 255, 255, 0.5)
		)
		no-repeat bottom center;
	background-size: 0 1px;
	text-transform: uppercase;
	white-space: nowrap;

	&:hover {
		background-size: 50% 1px;
	}

	&.active {
		background-size: 50% 1px;
		text-shadow: 0 0 1px currentColor;
	}

	@media (max-width: 840px) {
		font-size: 12px;
		line-height: 14px;
	}
`;

interface ISliderProps {
	hasScroll: boolean;
}

const Slider = styled.div<ISliderProps>`
	position: relative;
	width: 76%;
	overflow: hidden;
	padding: 30px 12%;

	${({hasScroll}) =>
		hasScroll
			? css`
					padding: 30px 0 30px 50%;
					width: 50%;
			  `
			: ""}
`;

const SliderLine = styled.hr`
	background: linear-gradient(
			to right,
			rgba(255, 255, 255, 0),
			#fff,
			rgba(255, 255, 255, 0)
		)
		no-repeat;
	height: 2px;
	margin: 0 0 60px 0;
	border: 0;
	display: block;

	@media (max-width: 840px) {
		margin-bottom: 50px;
	}
`;

const ButtonDrag = styled(PureButton)`
	background: url(${iconDrag}) #013369 no-repeat center center;
	background-size: auto 54%;
	cursor: grab;
	display: block;
	width: 60px;
	height: 60px;
	border-radius: 100%;
	transform: translateX(-50%);
	position: absolute;
	left: 50%;
	top: 0;
	transition: all 0.5s;
	z-index: 2;

	& > svg {
		pointer-events: none;
	}

	&:hover {
		background-color: #002247;
	}

	&.in-drag {
		transition: none;
		cursor: grabbing;
	}
`;

const SliderContent = styled.div`
	display: inline-block;
	min-width: 100%;
	transform: translateX(0);
	transition: all 0.5s;
`;

const Title = styled(QuestionTitle)`
	padding-right: 0;
`;

const AUTO_SCROLL_INTERVAL_SEC = 3.5;

export const QuestionSlider: React.FC<IQuestion> = ({
	title,
	options,
	id: questionID,
}) => {
	const dragRef = useRef<HTMLButtonElement>(null);
	const sliderRef = useRef<HTMLDivElement>(null);
	const hasScroll = useHasScroll(sliderRef);

	const [activeIndex, setActiveIndex] = useState<number | null>(null);
	const [autoTransitionActiveIndex, setAutoTransitionActiveIndex] = useState(
		0
	);
	const {UIQuestionsProgress} = useStore();
	const autoTransition = activeIndex === null;
	const imagesSize = size(options);

	const getActiveOptionClass = useCallback(
		(index: number) => (index === activeIndex ? "active" : ""),
		[activeIndex]
	);

	const getActiveImageClass = useCallback(
		(index: number) => {
			if (autoTransition) {
				return index === autoTransitionActiveIndex ? "active" : "";
			}

			return index === activeIndex ? "active" : "";
		},
		[activeIndex, autoTransitionActiveIndex, autoTransition]
	);

	useEffect(() => {
		if (!autoTransition || !imagesSize) {
			return;
		}

		const timeoutID = setTimeout(() => {
			const newIndex = (autoTransitionActiveIndex + 1) % imagesSize;
			setAutoTransitionActiveIndex(newIndex);
		}, AUTO_SCROLL_INTERVAL_SEC * 1000);

		return () => {
			clearTimeout(timeoutID);
		};
	}, [autoTransition, imagesSize, autoTransitionActiveIndex]);

	const moveSlide = useMemo(() => {
		const callback = hasScroll ? startDrag : startSlide;

		return callback(
			setActiveIndex,
			UIQuestionsProgress.selectAnswer.bind(UIQuestionsProgress)
		);
	}, [UIQuestionsProgress, hasScroll]);

	const onSelectOption = useCallback(
		(event: React.SyntheticEvent<HTMLButtonElement>) => {
			if (hasScroll) {
				return;
			}

			const element = event.currentTarget;
			const {index, questionId, optionId} = element.dataset;

			setActiveIndex(toInteger(index));
			UIQuestionsProgress.selectAnswer(
				toInteger(questionId),
				toInteger(optionId)
			);

			updateDragIconPosition(element, dragRef.current);
		},
		[UIQuestionsProgress, dragRef, hasScroll]
	);

	return (
		<Wrapper>
			<ImagesList>
				{options.map(({image, title, id}, index) => (
					<BGImage
						className={getActiveImageClass(index)}
						imageSrc={image}
						key={id}
					/>
				))}
			</ImagesList>
			<Container>
				<QuestionContentContainer>
					<Logo type="big" />
					<Title>{title}</Title>
				</QuestionContentContainer>
				<Slider hasScroll={hasScroll} ref={sliderRef}>
					<ButtonDrag
						ref={dragRef}
						onMouseDown={moveSlide}
						onTouchStart={moveSlide}
					/>
					<SliderContent>
						<SliderLine />
						<OptionsList>
							{options.map(({id, title, image}, index) => (
								<Option
									key={id}
									className={getActiveOptionClass(index)}
									data-question-id={questionID}
									data-option-id={id}
									data-index={index}
									onClick={onSelectOption}
								>
									{title}
								</Option>
							))}
						</OptionsList>
					</SliderContent>
				</Slider>
			</Container>
		</Wrapper>
	);
};
