import {HTTPClient} from "@fanhubmedia/fe-common-utils";
import {AxiosError} from "axios";
import {API_URL, JSON_URL, LOCALE} from "modules/constant";
import {ApiError} from "./ApiError";

const onCatchNetworkError = (response: AxiosError) =>
	Promise.reject(response.response?.data).catch((err) => ApiError.CHECK(err));

const APIClient = new HTTPClient({
	baseURL: API_URL,
	onCatchNetworkError,
});

const JSONClient = new HTTPClient({
	baseURL: JSON_URL,
	onCatchNetworkError,
});

interface IAnswersPayload {
	user_key: string;
	answers: {
		option_id: number;
	}[];
}

export const Api = {
	JSON: {
		squads: <T>() => JSONClient.get<T>("squads.json"),
		questions: <T>() => JSONClient.get<T>(`questions/${LOCALE}.json`),
		lang: <T>() => JSONClient.get<T>(`langs/${LOCALE}.json`),
	},
	Country: {
		get: <T>() => APIClient.get<T>("country"),
	},
	Answers: {
		save: <T>(params: IAnswersPayload) =>
			APIClient.post<T>("answers/save", params),
	},
};

export * from "./ApiError";
