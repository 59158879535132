import {QuestionType} from "modules/constant";

export class Question {
	public id: number = 0;
	public title: string = "";
	public short_title: string = "";
	public type: QuestionType = QuestionType.General;
	public background: null | string = null;
	public country_include: string[] | null = [];
	public country_exclude: string[] | null = [];
	public options: Array<{
		id: number;
		title: string;
		image: string;
		points: Record<string, number>;
	}> = [];

	constructor(args: Question) {
		Object.assign(this, args);
	}
}

export interface IQuestion extends Question {
	hasAnswer: boolean;
	isActive: boolean;
	questionNumber: number;
}
