import {FB_SHARE_URL} from "modules/constant";
import {createConnextraScriptTag, track} from "modules/utils/analytic";

interface IShareObject {
	t?: string;
	squadId: number;
	lang: string;
	user_id?: string;
}

/**
 * in order to reduce the size of the string for sharing
 */
const last_four_number = -4;
const getTimestamp = () => Date.now().toString().substr(last_four_number);

const getEncodedJson = (object: IShareObject) => btoa(JSON.stringify(object));

const getLinkForShare = (params: IShareObject) => {
	return `${FB_SHARE_URL}${getEncodedJson(params)}`;
};

export const shareFB = (params: IShareObject) => {
	const FB_API = window.FB;

	if (FB_API) {
		params.t = getTimestamp();

		track({
			linkType: "Share",
			linkName: "Facebook",
		});

		window.customGTagTrack({sendToPart: "000"});
		createConnextraScriptTag(
			`https://us.connextra.com/dcs/tagController/tag/11935e1e2233/f2p_teampicker_shareclick_facebook?AccountID=${
				params?.user_id || ""
			}`
		);

		FB_API.ui({
			method: "share",
			display: "popup",
			href: getLinkForShare(params),
		});
	}
};

export const shareTwitter = (params: IShareObject, copy: string) => {
	const href = getLinkForShare(params);
	params.t = getTimestamp();

	track({
		linkType: "Share",
		linkName: "Twitter",
	});

	window.customGTagTrack({sendToPart: "001"});
	createConnextraScriptTag(
		`https://us.connextra.com/dcs/tagController/tag/11935e1e2233/f2p_teampicker_shareclick_twitter?AccountID=${
			params?.user_id || ""
		}`
	);

	window.open(
		"https://twitter.com/share?url=" +
			encodeURIComponent(href) +
			"&text=" +
			encodeURIComponent(copy),
		"twitter-share-dialog",
		"width=626,height=436"
	);
};
