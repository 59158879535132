import React from "react";
import styled from "styled-components";
import {PureButton} from "components/Button";
import IconTW from "assets/img/IconTW.svg";
import IconFB from "assets/img/IconFB.svg";
import {observer} from "mobx-react";
import {useStore} from "modules/hooks";

const ShareButton = styled(PureButton)`
	background-position: center center;
	background-repeat: no-repeat;
	width: 40px;
	height: 40px;
	color: #fff;
	border: 1px solid currentColor;
	transition: opacity, 0.25s ease-in-out;
	display: inline-block;
	vertical-align: middle;

	&:hover {
		opacity: 0.4;
	}
`;

export const ShareFacebook = styled(ShareButton)`
	background-image: url(${IconFB});
`;

export const ShareTwitter = styled(ShareButton)`
	background-image: url(${IconTW});
`;

const SocialSharing = styled.div`
	${ShareFacebook} {
		margin-right: 15px;
	}
`;

const TW_NAME_BY_COUNTRY: Record<string, string> = {
	general: "NFL",
	ca: "NFLCanada",
	de: "NFLDeutschland",
	fr: "NFLFrance",
	mx: "nflmx",
};

export const SocialShareLinks = observer(() => {
	const {region} = useStore();
	const twitterName =
		TW_NAME_BY_COUNTRY[region.country] || TW_NAME_BY_COUNTRY.general;

	return (
		<SocialSharing>
			<ShareFacebook
				as="a"
				target="_blank"
				rel="nofollow noreferrer"
				href="https://www.facebook.com/NFL"
			/>
			<ShareTwitter
				as="a"
				target="_blank"
				rel="nofollow noreferrer"
				href={`https://twitter.com/${twitterName}`}
			/>
		</SocialSharing>
	);
});
