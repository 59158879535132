import React, {useCallback} from "react";
import styled from "styled-components";
import {HamburgerButton} from "./HamburgerButton";
import {MenuItem} from "./MenuItem";
import {useStore} from "modules/hooks";
import {observer} from "mobx-react";
import {size} from "lodash";
import {SocialShareLinks} from "components/Share";
import {LanguageSwitch} from "components/LanguageSwitch";
import {Logo} from "components/Logo";

const Wrapper = styled.div`
	position: fixed;
	left: 0;
	top: 0;
	z-index: 11;
	height: 100vh;
	width: 0;
`;

const MenuBar = styled.div`
	background: #013369;
	height: 100%;
	width: 400px;
	position: relative;
	z-index: 1;
	transition: all 0.5s;
	overflow: hidden;
	left: -380px;

	&.open {
		left: 0;
	}

	@media (max-width: 840px) {
		left: -390px;
	}

	@media (max-width: 400px) {
		width: 320px;
		left: -310px;
	}
`;

const MenuBarInner = styled.div`
	padding: calc(9vh + 65px) 0 200px;
	width: 400px;
	height: 100%;
	box-sizing: border-box;

	@media (max-width: 840px) {
		padding: calc(8vh + 65px) 0 180px;
	}

	@media (max-width: 400px) {
		width: 320px;
	}
`;

const StepListScroll = styled.div`
	height: 100%;
	overflow: auto;
`;

const StepList = styled.div`
	display: flex;
	flex-flow: column;
	min-height: 100%;
	justify-content: center;
`;

const Bottom = styled.div`
	position: absolute;
	left: 5.6vw;
	bottom: 52px;

	@media (max-width: 840px) {
		bottom: 48px;
	}
`;

const LanguagePicker = styled(LanguageSwitch)`
	margin-bottom: 30px;
`;

export const Menu: React.FC = observer(() => {
	const {UIQuestionsProgress, I18n} = useStore();
	const {isMenuOpen} = UIQuestionsProgress;
	const openClassName = isMenuOpen ? "open" : "";

	const openMenu = useCallback(() => {
		UIQuestionsProgress.toggleMenu(true);
	}, [UIQuestionsProgress]);

	const closeMenu = useCallback(
		({currentTarget, relatedTarget}: React.MouseEvent<HTMLDivElement>) => {
			if (relatedTarget === window) {
				return;
			}
			if (currentTarget.contains(relatedTarget as Node)) {
				return;
			}

			UIQuestionsProgress.toggleMenu(false);
		},
		[UIQuestionsProgress]
	);

	const toggleMenu = useCallback(() => {
		UIQuestionsProgress.toggleMenu();
	}, [UIQuestionsProgress]);

	const items = UIQuestionsProgress.allItems;
	const isQuizComplete = UIQuestionsProgress.isAllQuestionsAnswered;
	const finalScreenIndex = size(items);

	const finalScreenData = {
		id: UIQuestionsProgress.finalScreenID,
		short_title: I18n.t("menu.items.last.title"),
		hasAnswer: isQuizComplete,
		isActive: isQuizComplete,
		index: finalScreenIndex,
	};

	return (
		<Wrapper onMouseLeave={closeMenu}>
			<HamburgerButton className={openClassName} onClick={toggleMenu} />
			<Logo />
			<MenuBar className={openClassName} onMouseEnter={openMenu}>
				<MenuBarInner>
					<StepListScroll>
						<StepList>
							{items.map((question, index) => (
								<MenuItem
									key={question.id}
									index={index}
									{...question}
								/>
							))}
							<MenuItem showTitle={true} {...finalScreenData} />
						</StepList>
					</StepListScroll>
					<Bottom>
						<LanguagePicker />
						<SocialShareLinks />
					</Bottom>
				</MenuBarInner>
			</MenuBar>
		</Wrapper>
	);
});
