import React from "react";
import {QuestionWrapper} from "./common";
import {observer} from "mobx-react";
import styled, {keyframes} from "styled-components";
import {useStore} from "modules/hooks";
import {Logo} from "components";
import Confetti from "react-confetti";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const Wrapper = styled(QuestionWrapper)`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 80px 26px 140px;
	box-sizing: border-box;
	background-color: #000;
	overflow: hidden;
`;

interface IBackgroundImageProps {
	background?: string;
}

const BackgroundImage = styled.div<IBackgroundImageProps>`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: url(${({background}) => background});
	background-position: center;
	background-size: cover;
	animation: ${fadeIn} 2s ease-in-out 3s;
	animation-fill-mode: both;
`;

const LogoWrapper = styled.div`
	animation: ${fadeIn} 2s ease-in-out 1s;
	animation-fill-mode: both;
`;

const Title = styled.h3`
	text-align: center;
	font-size: 30px;
	letter-spacing: 3.75px;
	margin: 40px 0 60px;
	animation: ${fadeIn} 2s ease-in-out 2s;
	animation-fill-mode: both;

	@media (max-width: 840px) {
		margin: 40px 0 30px;
	}
`;

const TeamLogo = styled.img`
	height: 460px;
	margin-bottom: 20px;
	animation: ${fadeIn} 2s ease-in-out 3s;
	animation-fill-mode: both;

	@media (max-width: 840px) {
		height: 260px;
		margin-bottom: 30px;
	}
`;

const TeamName = styled.h1`
	text-align: center;
	font-size: 90px;
	font-weight: bold;
	letter-spacing: 3.6px;
	animation: ${fadeIn} 2s ease-in-out 3s;
	animation-fill-mode: both;

	@media (max-width: 840px) {
		font-size: 50px;
		letter-spacing: 2px;
	}
`;

export const ResultScreen: React.FC = observer(() => {
	const {UISquadList, I18n, UISquadInfo} = useStore();
	const topSquad = UISquadList.topSquad;
	const squadInfo = UISquadInfo.getSquadInfo(topSquad?.id);

	return (
		<Wrapper>
			<BackgroundImage background={squadInfo?.backgroundImage} />
			<LogoWrapper>
				<Logo type="small" />
			</LogoWrapper>
			<Title>{I18n.t("screens.result.title")}</Title>
			<TeamLogo src={squadInfo?.logo} alt={topSquad?.full_name} />
			<TeamName>{topSquad?.full_name}</TeamName>
			<Confetti
				width={3000}
				height={1000}
				colors={["#9b172f", "#d4c3c7", "#3b61d7"]}
				numberOfPieces={600}
			/>
		</Wrapper>
	);
});
