import {makeObservable, observable} from "mobx";
import SquadsInfoDataset from "modules/stores/UIStores/SquadsInfoDataset";
import {RootStore} from "modules/stores/RootStore";

interface ISquadInfo {
	logo: string;
	backgroundImage: string;
	runnerUpImage: string;
	videoImage: string;
	videoLink: string;
	NFLGamePass: {
		image: {
			general: string;
			[country: string]: string;
		};
		link: {
			general: string;
			[country: string]: string;
		};
	};
	NFLShop: {
		image: {
			general: string;
			[country: string]: string;
		};
		link: {
			general: string;
			[country: string]: string;
		};
	};
}

export class UISquadInfo<TModel extends RootStore> {
	@observable public data: Record<string, ISquadInfo> = SquadsInfoDataset;

	constructor(
		private readonly rootStore: Pick<TModel, "region" | "UISquadList">
	) {
		makeObservable(this);
	}

	getSquadInfo(squadID: number) {
		if (!squadID) {
			return null;
		}

		const squadInfo = this.data[squadID];

		if (!squadInfo) {
			return null;
		}

		const country = this.rootStore.region.country;
		const {
			logo,
			backgroundImage,
			runnerUpImage,
			videoLink,
			videoImage,
			NFLGamePass,
			NFLShop,
		} = squadInfo;
		const gamePassImage = NFLGamePass.image;
		const gamePassLink = NFLGamePass.link;
		const shopImage = NFLShop.image;
		const shopLink = NFLShop.link;

		return {
			logo,
			backgroundImage,
			runnerUpImage,
			videoImage,
			videoLink,
			gamePassImage: gamePassImage[country] || gamePassImage.general,
			gamePassLink: gamePassLink[country] || gamePassLink.general,
			shopImage: shopImage[country] || shopImage.general,
			shopLink: shopLink[country] || shopLink.general,
		};
	}
}
