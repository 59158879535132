import React from "react";
import styled from "styled-components";
import geniusSportLogo from "assets/img/geniusSportLogo.png";
import {useStore} from "modules/hooks";
import {observer} from "mobx-react";
import {SocialShareLinks} from "components/Share";

const Wrapper = styled.div`
	width: 100%;
	padding: 50px 0 0;
	box-sizing: border-box;
	font-size: 12px;
	letter-spacing: 2px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const LeftPart = styled.div`
	line-height: 22px;

	p {
		margin-bottom: 12px;

		&:first-child {
			margin-bottom: 0;
		}
	}
`;

export const Footer: React.FC = observer(() => {
	const {I18n} = useStore();

	return (
		<Wrapper>
			<LeftPart>
				<p>{I18n.t("global.footer.title")}</p>
				<p>{I18n.t("global.footer.desc")}</p>
				<SocialShareLinks />
			</LeftPart>
			<a
				href={
					"https://geniussports.com/media-engagement/free-to-play-games/"
				}
				rel="noreferrer"
				target={"_blank"}
			>
				<img src={geniusSportLogo} alt={"GeniusSport"} />
			</a>
		</Wrapper>
	);
});
