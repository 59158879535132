import styled from "styled-components";

export const PureButton = styled.button`
	border: 0;
	padding: 0;
	margin: 0;
	background: none;
	cursor: pointer;
`;

export const Button = styled.button`
	border: none;
	outline: 0;
	background-color: #143cab;
	color: #ffffff;
	cursor: pointer;
	padding: 16px;
	font-size: 16px;
	font-family: "AllProDisplayC", Arial, sans-serif;
	letter-spacing: 2px;
	border-radius: 4px;
	min-width: 280px;
	transition: opacity 0.25s ease-out;

	&:hover {
		opacity: 0.8;
	}
`;
