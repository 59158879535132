import React, {useState} from "react";
import {IQuestion} from "modules/stores/Models";
import {
	QuestionWrapper,
	QuestionTitle,
	QuestionContainer,
	QuestionCounter,
} from "./common";
import styled from "styled-components";
import backgroundGeneralQuestion from "assets/img/backgroundGeneralQuestion.png";
import {useStore} from "modules/hooks";
import {IMAGES_URL} from "modules/constant";
import {PureButton} from "components/Button";

interface IWrapperProps {
	background: null | string;
}

const getWrapperBackground = ({background}: IWrapperProps) =>
	background ? `${IMAGES_URL}image/${background}` : backgroundGeneralQuestion;

const Wrapper = styled(QuestionWrapper)<IWrapperProps>`
	background: url(${getWrapperBackground}) no-repeat center center / cover;
`;

const OptionsList = styled.div`
	position: relative;
	display: flex;
	flex-flow: column;
	align-items: flex-start;
`;

const Option = styled(PureButton)`
	cursor: pointer;
	transition: all 0.5s;
	font-size: 90px;
	line-height: 95px;
	font-family: "AllProDisplayC", Arial, sans-serif;
	font-weight: 900;
	color: #ccc;

	&.active {
		opacity: 1;
	}

	&.fade {
		opacity: 0.5;
	}

	@media (max-width: 1100px) {
		font-size: 36px;
		line-height: 40px;
		margin-bottom: 10px;
	}
`;

const getStateClass = (currentID: number, selectedID: number | null) => {
	if (selectedID === null) {
		return "";
	}

	if (currentID === selectedID) {
		return "active";
	}

	return "fade";
};

export const QuestionText: React.FC<IQuestion> = ({
	title,
	options,
	id: questionID,
	background,
	questionNumber,
}) => {
	const {UIQuestionsProgress} = useStore();
	const [selectedID, setSelectedID] = useState<null | number>(null);
	const noOfQuestions = UIQuestionsProgress.noOfQuestions;

	return (
		<Wrapper background={background}>
			<QuestionContainer>
				<QuestionCounter>
					{questionNumber}/{noOfQuestions}
				</QuestionCounter>
				<QuestionTitle>{title}</QuestionTitle>
				<OptionsList>
					{options.map(({id, title}) => (
						<Option
							key={id}
							className={getStateClass(id, selectedID)}
							onClick={() => {
								UIQuestionsProgress.selectAnswer(
									questionID,
									id
								);
								setSelectedID(id);
							}}
						>
							{title}
						</Option>
					))}
				</OptionsList>
			</QuestionContainer>
		</Wrapper>
	);
};
