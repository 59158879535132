import React, {useEffect, useRef, useState} from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {useMediaQuery, useStore} from "modules/hooks";
import {IMAGES_URL} from "modules/constant";
import {Flipper, Flipped} from "react-flip-toolkit";
import {PureButton} from "components/Button";
import {Exist} from "components/Exist";
import {track} from "modules/utils";

const Wrapper = styled.div`
	position: fixed;
	right: 50px;
	top: 8vh;
	max-height: 84vh;
	width: 320px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	border-radius: 2px;
	z-index: 10;
`;

const Header = styled.div`
	background: rgba(255, 255, 255, 0.25);
	padding: 15px 20px;
	font-size: 14px;
	text-align: center;
	letter-spacing: 1px;
`;

const SQUAD_ITEM_HEIGHT = 40;

const SquadItem = styled.div`
	background: linear-gradient(to bottom, #e7e7e7, #fff);
	height: ${SQUAD_ITEM_HEIGHT}px;
	display: flex;
	align-items: center;
	padding: 0 20px 0 0;
	font-size: 12px;
`;

interface IListProps {
	isExpanded: boolean;
}

const List = styled.div<IListProps>`
	background: #fff;
	color: #000;
	max-height: ${(props) =>
		props.isExpanded ? "100vh" : `${SQUAD_ITEM_HEIGHT * 3}px`};
	transition: all 0.5s linear;
	overflow: auto;
`;

const Number = styled.div`
	color: #ccc;
	font-size: 12px;
	margin-right: 15px;
	font-weight: 900;
	width: 40px;
	text-align: right;
`;

const SquadLogoFigure = styled.div`
	margin-right: 10px;
	width: 30px;
`;

const SquadLogo = styled.img`
	width: 100%;
	max-width: 100%;
`;

const Name = styled.div`
	font-size: 14px;
	color: #151515;
	letter-spacing: 1px;
`;

const Button = styled.button`
	background: linear-gradient(to bottom, #ddd, #eee);
	width: 100%;
	height: 40px;
	min-height: 40px;
	cursor: pointer;
	border: 0;
	font-family: "AllProDisplayC", Arial, serif;
	font-size: 12px;
	text-transform: uppercase;
	color: #555;
	letter-spacing: 5px;
`;

const MobileButton = styled(PureButton)`
	background: #fff;
	position: fixed;
	right: 15px;
	top: 8vh;
	width: 60px;
	height: 60px;
	overflow: hidden;
	border-radius: 100%;
	z-index: 10;
	display: flex;
	align-items: center;
	justify-content: center;
	transform: translateY(-25%);

	img {
		max-width: 30px;
		height: auto;
	}
`;

const CloseButton = styled(MobileButton)`
	background: #000;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);

	&:before,
	&:after {
		background: #fff;
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		width: 21px;
		height: 3px;
	}

	&:before {
		transform: translate(-50%, -50%) rotate(45deg);
	}

	&:after {
		transform: translate(-50%, -50%) rotate(-45deg);
	}
`;

const Overlay = styled.div`
	background: rgba(0, 0, 0, 0.5);
	position: fixed;
	height: 100%;
	width: 100%;
	z-index: 15;
	display: flex;
	justify-content: flex-end;
`;

const MobileListContainer = styled.div`
	min-width: 320px;
	display: flex;
	flex-flow: column;

	${Header} {
		padding-top: 120px;
	}

	${List} {
		flex: 1;
	}
`;

const QuizStart = styled.div`
	background-color: #fff;
	color: #292929;
	padding: 24px;
	font-size: 14px;
	letter-spacing: 2px;
	line-height: 18px;
	text-align: center;
`;

export const SquadsList: React.FC = observer(() => {
	const listRef = useRef<HTMLDivElement>(null);
	const {squads, UIQuestionsProgress, UISquadList, I18n} = useStore();
	const isQuizStarted = UIQuestionsProgress.isAnyQuestionAnswered;
	const {isExpanded} = UISquadList;
	const [isMobileListVisible, setMobileListVisibleState] = useState(false);
	const isMobileView = useMediaQuery("(max-width: 1000px)");

	useEffect(() => {
		if (isMobileListVisible) {
			track({
				linkType: "modal",
				linkName: "View Teams",
			});
		}
	}, [isMobileListVisible]);

	useEffect(() => {
		void squads.request();
	}, [squads]);

	useEffect(() => {
		if (!isExpanded && listRef.current) {
			listRef.current.scroll({
				top: 0,
				left: 0,
				behavior: "smooth",
			});
		}
	}, [isExpanded, listRef]);

	const btn_copy = I18n.t(
		isExpanded ? "squads_list.btn.less" : "squads_list.btn.more"
	);

	if (isMobileView) {
		return (
			<React.Fragment>
				<MobileButton onClick={() => setMobileListVisibleState(true)}>
					<SquadLogo
						src={`${IMAGES_URL}image/squads/${UISquadList.topSquad?.id}.png`}
					/>
				</MobileButton>
				<Exist when={isMobileListVisible}>
					<Overlay>
						<CloseButton
							onClick={() => setMobileListVisibleState(false)}
						/>
						<MobileListContainer>
							<Header>
								{I18n.t("squads_list.header.title")}
							</Header>
							<Exist when={isQuizStarted}>
								<List isExpanded={true}>
									{UISquadList.items.map(
										({id, full_name}, index) => (
											<SquadItem key={id}>
												<Number>#{index + 1}</Number>
												<SquadLogoFigure>
													<SquadLogo
														src={`${IMAGES_URL}image/squads/${id}.png`}
													/>
												</SquadLogoFigure>
												<Name>{full_name}</Name>
											</SquadItem>
										)
									)}
								</List>
							</Exist>
							<Exist when={!isQuizStarted}>
								<QuizStart>
									{I18n.t("squads_list.content.quiz_start")}
								</QuizStart>
							</Exist>
						</MobileListContainer>
					</Overlay>
				</Exist>
			</React.Fragment>
		);
	}

	return (
		<Wrapper>
			<Header>{I18n.t("squads_list.header.title")}</Header>
			<Exist when={isQuizStarted}>
				<List ref={listRef} isExpanded={isExpanded}>
					<Flipper flipKey={UISquadList.renderVersion}>
						{UISquadList.items.map(
							({id, full_name, percentage}, index) => (
								<Flipped key={id} flipId={id}>
									<SquadItem>
										<Number>#{index + 1}</Number>
										<SquadLogoFigure>
											<SquadLogo
												src={`${IMAGES_URL}image/squads/${id}.png`}
											/>
										</SquadLogoFigure>
										<Name>{full_name}</Name>
									</SquadItem>
								</Flipped>
							)
						)}
					</Flipper>
				</List>
				<Button onClick={() => UISquadList.toggle()}>{btn_copy}</Button>
			</Exist>
			<Exist when={!isQuizStarted}>
				<QuizStart>
					{I18n.t("squads_list.content.quiz_start")}
				</QuizStart>
			</Exist>
		</Wrapper>
	);
});
